div#_company-group_ {
  #main-title {
    text-align: center;
    font-weight: bold;
    margin-bottom: 25px;
    @media only screen and (max-width: 600px) {
      margin-top: 25px;
    }
  }
  .section-card {
    background-color: #fff;
    border-radius: 6px;
    padding: 18px 12px;
    margin-bottom: 24px;
    -webkit-box-shadow: 0px 6px 15px -4px rgba(222, 222, 222, 1);
    -moz-box-shadow: 0px 6px 15px -4px rgba(222, 222, 222, 1);
    box-shadow: 0px 6px 15px -4px rgba(222, 222, 222, 1);
    .section-card-title {
      margin: 0 0 18px 0;
      font-weight: bold;
      text-align: center;
      span {
        border-radius: 30px;
        padding: 2px 8px;
        font-size: 12px;
        margin-left: 5px;
        &.module-active {
          color: #12ba90;
          background-color: #d2f0cd;
        }
        &.module-inactive {
          color: #f93d3d;
          background-color: #fee6e6;
        }
      }
    }
    .section-card-subtitle {
      font-weight: bold;
      font-size: 12px;
      margin: 12px 0 6px 0;
    }
  }
  #filters {
    text-align: right;
    #plus {
      background-color: #1e96dc;
      margin: 0 0 0 15px;
      cursor: pointer;
      color: #fff;
      stroke: #fff;
      stroke-width: 25px;
      padding: 8px;
      border-radius: 35px;
      width: 35px;
      height: 35px;
      text-align: center;
      vertical-align: middle;
      line-height: 35px;
    }
  }
  #employees-table {
    margin-bottom: 24px;
    .employee-row {
      .pos-index {
        margin-right: 5px;
        color: #a7a7a7;
      }
      .edit {
        background-color: #1e96dc;
        cursor: pointer;
        color: #fff;
        padding: 4px;
        border-radius: 20px;
        width: 20px;
        height: 20px;
        text-align: center;
        vertical-align: middle;
        line-height: 20px;
      }
    }
  }
}
