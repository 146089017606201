.H_ib_body {
  background-color: var(--airbag-lightBlack);
  text-align: center;
  color: white;
  border-radius: 15px;
  width: 250px;
  border-top: 4px solid var(--airbag-yellow);
  b,
  p {
    color: white;
  }
  .last-seen {
    font-size: 13px;
    font-weight: bold;
    span {
      color: var(--airbag-light);
    }
  }
  #cat-btn {
    background-color: var(--airbag-yellow);
    font-family: var(--airbag-font);
    font-weight: bold;
    color: var(--airbag-lightBlack);
    margin: 5px auto 0 auto;
    padding: 5px 12px;
    border-radius: 50px;
    display: inline-block;
  }
  #date {
    margin-top: 5px;
    font-size: 12px;
  }
  #log-description {
    margin-top: -10px;
    font-weight: bold;
  }
  #coordinates {
    margin-top: -10px;
    font-size: 11px;
    font-style: italic;
    margin-bottom: -10px;
  }
  .H_icon {
    fill: #fff;
  }
}
/** Style for the info bubble tail */
.H_ib.H_ib_top .H_ib_tail::after {
  border-color: var(--airbag-lightBlack) transparent;
}
