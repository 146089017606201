.stat {
  .circle-wrapper {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 10px solid #f3f3f3;
    margin: 0 auto;
    .circle {
      width: 80px;
      height: 80px;
      margin: 0 auto;
      text-align: center;
      border: 5px solid #b3b3b3;
      h5 {
        font-family: var(--airbag-font);
        font-weight: bold;
        margin: 0 auto;
        &.len-4 {
          font-size: 1.5rem;
        }
        &.len-5 {
          font-size: 1.2rem;
        }
        &.len-6 {
          font-size: 1rem;
        }
      }
    }
    .yellow-border {
      border-color: #f4db3a;
    }
    .red-border {
      border-color: #f93d3d;
    }
    .green-border {
      border-color: #12ba90;
    }
    .blue-border {
      border-color: #3bcbde;
    }
  }
  .yellow-border-light {
    border-color: #fcf6cf;
  }
  .green-border-light {
    border-color: #d0fbf1;
  }
  .red-border-light {
    border-color: #fee6e6;
  }
  .blue-border-light {
    border-color: #d4f3f7;
  }
}
