div#admin-company-ob {
	.company-name {
    font-family: var(--airbag-font);
    font-weight: bold;
    text-align: center;
    margin: 30px 0px 10px 10px;
  }
	#firestore-id {
    display: flex;
    width: 100%;
    justify-content: center;
    input {
      font-family: var(--airbag-font);
      color: var(--airbag-grey);
      display: inline-block;
      font-family: monospace;
      font-size: 12px;
      text-align: center;
      cursor: pointer;
      width: 100%;
      max-width: 250px;
      height: 25px;
      margin: 5px 0 !important;
    }
  }
  #_onboarding-modal_ {
    input, select {
      background-color: #f2f2f2;
    }
    select {
      display: block;
    }
    #main-t {
      text-align: center;
      margin-bottom: 25px;
    }
    #download-example {
      #download-icon {
        vertical-align: middle;
        width: 20px;
      }
    }
    .hidden {
      display: none;
    }
    #box-container {
      background-color: #f2f2f2;
      border-radius: 6px;
      text-align: center;
      padding: 21px 6px;
      margin-bottom: 21px;
      #upload-icon {
        vertical-align: middle;
        width: 36px;
        margin-bottom: 6px;
      }
      p {
        margin: 0;
      }
    }
  }
}