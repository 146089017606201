#_company-drivers_ {
  margin-top: 25px;
  select,
  input {
    display: inline-block;
    width: 100%;
    background-color: #fff;
  }
  .item-container {
    display: inline-block;
    @media only screen and (max-width: 600px) {
      margin-bottom: -10px;
      width: 100%;
    }
    @media only screen and (min-width: 601px) {
      width: 100%;
      margin-bottom: -4px;
    }
  }
  #query-clear {
    position: absolute;
    margin: 14px 0 0 -20px;
    color: #2f2f2f;
    cursor: pointer;
  }
  .hide-on-large-and-down {
    @media only screen and (max-width: 1200px) {
      display: none;
    }
  }
  #main-title {
    text-align: center;
    font-weight: bold;
    @media only screen and (max-width: 600px) {
      margin-top: 25px;
    }
  }
  #filters {
    margin-bottom: 0;
    input {
      background-color: #fff;
      width: 100%;
      max-width: 200px;
    }
    #add-icon {
      background-color: #1e96dc;
      cursor: pointer;
      color: #fff;
      padding: 8px;
      border-radius: 35px;
      width: 35px;
      height: 35px;
      text-align: center;
      vertical-align: middle;
      line-height: 35px;
    }
  }
  #no-data {
    text-align: center;
    margin-top: 25px;
    font-weight: 700;
    #error-icon {
      width: 100px;
      stroke: var(--airbag-grey);
    }
    p {
      margin-top: 0;
    }
  }
  #drivers-table {
    margin-top: 18px;
    .pos-index {
      margin-right: 5px;
      color: #a7a7a7;
    }
    tr {
      cursor: pointer;
    }
    .last-seen-color {
      font-weight: bold;
      text-align: center;
      font-size: 12px;
      &.bad {
        color: #c9131e;
      }
      &.regular {
        color: #FFB800;
      }
      &.good {
        color: #008E6C;
      }
      &.none {
        color: #797979;
      }
    }
    .pills-container {
      text-align: center;
      .pill {
        display: inline-block;
        text-align: center;
        width: 90px;
        padding: 3px 10px;
        border-radius: 42px;
        font-weight: 600;
        font-size: 10px;
        &.red-s {
          background-color: #ffdad6;
          color: #c91316;
        }
        &.yellow-s {
          background-color: #fef1d0;
          color: #ffb800;
        }
        &.green-s {
          background-color: #c3eddc;
          color: #008e6c;
        }
        &.grey-s {
          background-color: #dadada;
          color: #343538;
        }
      }
    }
  }
}
