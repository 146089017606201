div#_billing-reports_ {
  .newReportButton{
    margin-top: 5px;
  }
  .inputReport {
    background-color: #f3f3f3 !important;
    margin-left: 10px !important;
    padding: 14px 10px !important;
  }
  p {
    margin: 15px;
  }
  .icon {
    font-size: 18px;
    color: var(--airbag-lightBlack);
    margin-top: 10px;
    height: 30px;
    width: 15px;
    cursor: pointer;
  }
  #reports-table {
    margin-top: 20px;
    background-color: #f7f7f7;
    .report-row {
      &:hover {
        background-color: #f7f7f7;
      }
      .pos-index {
        margin-right: 5px;
        color: #a7a7a7;
      }
      .download-cta-wrapper {
        text-align: center;
      }
      .download-icon {
        cursor: pointer;
        color: var(--airbag-yellow);
      }
      .delete-icon {
        cursor: pointer;
        color: var(--airbag-alert);
      }
    }
  }
  #add-report-btn {
    margin-top: 15px;
  }
  #main-title {
    text-align: center;
    font-weight: bold;
    margin-bottom: 25px;
    @media only screen and (max-width: 600px) {
      margin-top: 25px;
    }
  }
  .airbag-table-container {
    margin-bottom: 12px;
  }
  #newReportForm {
    overflow: hidden;
    margin-top: 12px;
    background-color: #fff;
    h4 {
      font-weight: 600;
      font-size: x-large;
    }
    .reportFormContainer {
      margin-left: 24px;
    }
    #exp {
      color: #303030;
      font-size: 13px;
    }
  }

  .closeModalContainer {
    width: 100%;
    #close-modal-button {
      float: right;
      margin: 12px 24px 0 0;
      cursor: pointer;
    }
  }
}
