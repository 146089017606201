div#_realtime_ {
  h5 {
    margin-bottom: 20px;
  }
  .input-field {
    max-width: 380px;
    margin: 30px 0 20px 0;
  }
  .point {
    cursor: pointer;
    span {
      min-width: 40px;
      margin-right: 5px;
      display: inline-block;
      color: #b3b3b3;
    }
  }
}
