#admin-company {
  .company-name {
    font-family: var(--airbag-font);
    font-weight: bold;
    text-align: center;
    margin: 30px 0px 10px 10px;
  }
  #firestore-id {
    display: flex;
    width: 100%;
    justify-content: center;
    input {
      font-family: var(--airbag-font);
      color: var(--airbag-grey);
      display: inline-block;
      font-family: monospace;
      font-size: 12px;
      text-align: center;
      cursor: pointer;
      width: 100%;
      max-width: 250px;
      height: 25px;
      margin: 5px 0 !important;
    }
  }
  #filters {
    text-align: right;
    margin-bottom: 15px;
    #edit-icon {
      background-color: #1e96dc;
      cursor: pointer;
      stroke: #fff;
      padding: 4px;
      border-radius: 35px;
      width: 35px;
      height: 35px;
      text-align: center;
      vertical-align: middle;
      line-height: 35px;
    }
  }
  #company-info {
    background-color: #fff;
    border-radius: 6px;
    margin-left: 1px !important;
    margin-right: 1px !important;
    margin-bottom: 9px;
    text-align: center;
    padding: 12px 0;
    -webkit-box-shadow: 0px 6px 15px -4px rgba(222, 222, 222, 1);
    -moz-box-shadow: 0px 6px 15px -4px rgba(222, 222, 222, 1);
    box-shadow: 0px 6px 15px -4px rgba(222, 222, 222, 1);
    .table-title {
      font-weight: bold;
      margin: 12px 0 5px 0;
    }
    .subtitle {
      padding-top: 18px;
      margin: 0;
      color: #6b6b6b;
      font-size: 12px;
    }
    .tableValue {
      margin: 0 0 12px 0;
      #external-link {
        margin: 0 0 -5px 8px;
        stroke: #1e96dc;
      }
    }
  }
  #link-to-onboardings {
    margin: 10px;
  }
  #menu-container {
    background-color: #fff;
    border-radius: 6px;
    padding: 12px 6px 0 6px;
    overflow: auto;
    white-space: nowrap;
    .sec {
      padding: 3px 18px 6px 18px;
      font-size: 12px;
      color: #8d8d8d;
      cursor: pointer;
      font-weight: bold;
      display: inline-block;
      margin: 0 0 12px 0;
      transition: 0.2s;
      border-bottom-color: var(--airbag-yellow);
      &:hover {
        .sec-content {
          color: #000;
          border-bottom: 2px solid var(--airbag-yellow);
        }
      }
      &:not(:last-child) {
        border-right: 1px solid #ccc;
      }
      .sec-content {
        padding: 3px 3px 6px 3px;
      }
      &.active {
        .sec-content {
          color: #000;
          border-bottom: 2px solid var(--airbag-yellow);
        }
        #beta {
          color: #000;
        }
        .sect-icon {
          stroke: #000
        }
      }
      #beta {
        font-size: 9px;
        font-weight: lighter;
      }
      .sect-icon {
        margin: 0 5px -3px 0;
        width: 15px;
        stroke: #848484;
      }
    }
  }
  #add-user-form {
    margin-top: 45px;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    .divider {
      margin: 10px 0 35px 0;
    }
    .sub {
      font-weight: bold;
      letter-spacing: 0.5px;
    }
    input {
      background-color: #f2f2f2;
    }
    button {
      margin: 10px 0 0 0;
    }
  }
}
