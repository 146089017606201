._driver_ {
  select {
    background-color: #f2f2f2 !important;
  }
  #driver-menu {
    background-color: #fff;
    border-radius: 6px;
    padding: 12px 6px 0 6px;
    overflow: auto;
    white-space: nowrap;
    margin-bottom: 20px;
    .sec {
      padding: 3px 18px 6px 18px;
      font-size: 12px;
      color: #8d8d8d;
      cursor: pointer;
      font-weight: bold;
      display: inline-block;
      margin: 0 0 12px 0;
      transition: 0.2s;
      border-bottom-color: var(--airbag-yellow);
      &:hover {
        .sec-content {
          color: #000;
          border-bottom: 2px solid var(--airbag-yellow);
        }
      }
      &:not(:last-child) {
        border-right: 1px solid #ccc;
      }
      .sec-content {
        padding: 3px 3px 6px 3px;
      }
      &.active {
        .sec-content {
          color: #000;
          border-bottom: 2px solid var(--airbag-yellow);
        }
        #beta {
          color: #000;
        }
        .sect-icon {
          stroke: #000;
        }
      }
      #beta {
        font-size: 9px;
        font-weight: lighter;
      }
      .sect-icon {
        margin: 0 5px -3px 0;
        width: 15px;
        stroke: #848484;
      }
    }
  }
  .status {
    min-width: 100px;
    padding: 3px 10px;
    border-radius: 42px;
    font-weight: 600;
    font-size: 10px;
    &.red-s {
      background-color: #ffdad6;
      color: #c9131e;
    }
    &.yellow-s {
      background-color: #fef1d0;
      color: #ffb800;
    }
    &.green-s {
      background-color: #c3eddc;
      color: #008e6c;
    }
    &.grey-s {
      background-color: #eee;
      color: #797979;
    }
  }

  #slider-container {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;

    input[type='checkbox'] {
      height: 0;
      width: 0;
      visibility: hidden;
    }

    label {
      cursor: pointer;
      text-indent: -9999px;
      width: 60px;
      height: 30px;
      background: rgb(104, 104, 104);
      display: block;
      border-radius: 100px;
      position: relative;
    }

    label:after {
      content: '';
      position: absolute;
      top: 5px;
      left: 5px;
      width: 20px;
      height: 20px;
      background: #fff;
      border-radius: 90px;
      transition: 0.3s;
    }

    input:checked + label {
      background: #e2cc00;
    }

    input:checked + label:after {
      left: calc(100% - 5px);
      transform: translateX(-100%);
    }
  }

  textarea {
    background-color: #f2f2f2 !important;
    margin-bottom: 10px !important;
    border: 0px;
    height: 90px !important;
  }
  #motive {
    margin-bottom: 20px !important;
  }
  input {
    background-color: #f2f2f2 !important;
  }
  .icon {
    cursor: pointer !important;
  }
  .airbag-table-container {
    width: 100% !important;
  }
  #name {
    text-align: center;
    margin-bottom: 25px;
    h5 {
      font-weight: bold;
      margin-bottom: 0;
    }
    #firestore-id {
      input {
        display: inline-block;
        font-family: monospace;
        font-size: 12px;
        text-align: center;
        cursor: pointer;
        width: 100%;
        max-width: 250px;
        height: 25px;
        margin: 5px 0 !important;
      }
    }
    #type {
      margin: 5px 0 10px 0;
      color: #6f6f6f;
      font-size: 12px;
      font-style: italic;
      font-weight: 100px;
    }
    p {
      margin: 0;
    }
    button {
      margin-top: 10px;
    }
  }
  .big-label {
    margin-bottom: 0;
    p {
      margin-bottom: 20px;
      font-weight: bold;
      color: #464646;
    }
  }
  .phone {
    background-color: #f2f2f2 !important;
    padding: 11px 10px;
    height: 3rem;
    border-radius: 40px;
    margin-top: 0;
  }
  .fake-input {
    background-color: #f2f2f2;
    padding: 11px 10px;
    height: 3rem;
    border-radius: 40px;
    margin-top: 0;
  }
  .notif {
    tr {
      border-bottom: 1px solid #f2f2f2;
      td {
        padding: 8px 5px;
        .green-t {
          color: #12ba90;
        }
        .red-t {
          color: #f93d3d;
        }
        .notif-source {
          font-size: 14px;
          &.admin-link {
            cursor: pointer;
          }
          svg {
            color: #ccc;
            font-size: 14px;
            margin-right: 5px;
          }
        }
      }
    }
  }
}
