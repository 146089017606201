div#_company-groups_ {
	#main-title {
		text-align: center;
		font-weight: bold;
		margin-bottom: 25px;
		@media only screen and (max-width: 600px) {
			margin-top: 25px;
		}
	}
	#company-groups-table {
		.pos-index {
			margin-right: 5px;
			color: #a7a7a7;
		}
	}
}
