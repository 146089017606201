@font-face {
  font-family: 'PP Object Sans';
  src: url('/assets/fonts/PPObjectSans-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'PP Object Sans';
  src: url('/assets/fonts/PPObjectSans-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'PP Object Sans';
  src: url('/assets/fonts/PPObjectSans-Light.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'PP Object Sans';
  src: url('/assets/fonts/PPObjectSans-Slanted.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'PP Object Sans';
  src: url('/assets/fonts/PPObjectSans-LightSlanted.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: PP Object Sans;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.capitalize {
  text-transform: capitalize;
}
.avenir {
  font-family: var(--airbag-font);
}
.bold {
  font-weight: bold;
}
.no-margin {
  margin: 0;
}
.yellow-c {
  color: #f4db3a;
}
.toast {
  font-family: var(--airbag-font);
}
.success-toast {
  background-color: #fafffa;
  color: #000;
  border-left: 6px solid #45c55f;
}
.error-toast {
  background-color: #fffafa;
  color: #000;
  border-left: 6px solid #f93d3d;
}
.info-toast {
  background-color: #e5fcff;
  color: #000;
  border-left: 6px solid #00acc1;
}
.modal {
  font-family: var(--airbag-font);
  .modal-content {
    .close-modal {
      float: right;
      font-size: 30px;
      margin: -15px 0 0 0;
    }
    .title {
      font-family: var(--airbag-font);
      font-weight: bold;
      margin: 20px 0 25px 0;
    }
  }
}
input:focus + label {
  color: #9e9e9e !important;
}
textarea:focus,
input:focus,
select:focus {
  // border-bottom: 0 !important;
  // box-shadow: 0 0 0 0 #fff !important;
  // border: 2px solid #efefef !important;
  border: 1px solid #f4db3a !important;
  -webkit-box-shadow: 0px 0px 0px 3px rgba(252, 245, 207, 1) !important;
  -moz-box-shadow: 0px 0px 0px 3px rgba(252, 245, 207, 1) !important;
  box-shadow: 0px 0px 0px 3px rgba(252, 245, 207, 1) !important;
}
input,
textarea,
select {
  font-family: var(--airbag-font);
  outline: none !important;
  margin: 0 !important;
}
input {
  border-radius: 40px !important;
  border: 1px solid transparent !important;
  padding: 0 8px !important;
  box-sizing: border-box !important;
}
textarea {
  border-radius: 15px !important;
  border-bottom: 0 !important;
  padding: 10px !important;
}
select {
  cursor: pointer;
  border-radius: 40px !important;
  border: 1px solid transparent !important;
  box-sizing: border-box !important;
}
label {
  font-family: var(--airbag-font);
  margin-left: 10px;
  margin-top: -4px;
  font-size: 14px;
}
.switch label input[type='checkbox']:checked + .lever {
  background-color: #adadad;
}
// .switch label .lever:before {
//   background-color: #464646
// }
.switch label input[type='checkbox']:checked + .lever:after {
  background-color: #5a5a5a;
}
input[type='checkbox'].filled-in:checked + span:not(.lever):after {
  border: 0;
  background-color: #1e96dc;
}
input[type='checkbox'].filled-in:checked + span:not(.lever):before {
  border-right-color: #fff;
  border-bottom-color: #fff;
}
input[type='checkbox'].filled-in:not(:checked) + span:not(.lever):after {
  border: 1px solid #5a5a5a;
}
.custom-modal {
  position: fixed;
  z-index: 30;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  .modal-content {
    background-color: #fefefe;
    margin: 100px auto 0 auto;
    padding: 20px;
    width: 100%;
    max-width: 500px;
    font-family: var(--airbag-font);
  }
}
.stats {
  padding: 15px 0;
  margin: 0;
  .stat-wrapper {
    padding: 10px;
  }
}
.filters {
  margin-top: 30px;
  .box {
    height: 50px;
    background-color: #dadada;
  }
}
.box-container {
  margin: 15px 0;
  background-color: #fff;
  border-radius: 6px;
  -webkit-box-shadow: 0px 6px 15px -4px rgba(222, 222, 222, 1);
  -moz-box-shadow: 0px 6px 15px -4px rgba(222, 222, 222, 1);
  box-shadow: 0px 6px 15px -4px rgba(222, 222, 222, 1);
  .dark {
    background-color: #fff;
  }
  .row {
    margin-right: 0;
    margin-left: 0;
    .col {
      margin-right: 0;
      margin-left: 0;
    }
  }
  .box-top-title {
    background-color: var(--airbag-lightBlack);
    color: #ffffff;
    margin: 0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    p {
      font-family: var(--airbag-font);
      font-weight: bold;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 1.2px;
    }
    button {
      font-size: 10px;
      padding: 6px 12px 4px 12px;
      margin-top: 10px;
    }
  }
  .box-content {
    overflow: auto;
    max-height: 312px;
  }
  .box-data {
    margin: 0;
    font-family: var(--airbag-font);
    border-radius: 6px;
    transition: 0.2s;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -o-transition: 0.2s;
    -ms-transition: 0.2s;
    &:hover {
      background-color: #f6f6f6;
    }
    border-top: 1px solid #f4f4f4;
    a {
      color: inherit;
    }
    p {
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
    .status {
      padding: 2px 10px;
      border-radius: 42px;
      font-weight: 600;
      font-size: 10px;
      &.active {
        background-color: #d0fbf1;
        color: #12ba90;
      }
      &.inactive {
        background-color: #fee6e6;
        color: #f93d3d;
      }
      &.red-s {
        background-color: #ffe0e0;
        color: #f93d3d;
      }
      &.yellow-s {
        background-color: #fff093;
        color: #9c8b17;
      }
      &.green-s {
        background-color: #d2f0cd;
        color: #12ba90;
      }
      svg {
        font-size: 8px;
        margin-right: 5px;
      }
    }
  }
}
.back-cta {
  margin: 20px 0 15px 0;
}
.airbag-table-container {
  overflow-x: scroll;
  border-radius: 6px;
  background-color: #fff;
  .airbag-top {
    padding: 6px 17px;
  }
  .airbag-table {
    display: table;
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    thead {
      color: #000;
      font-size: 13px;
      tr {
        border: 0;
        .sort-arrow {
          margin-left: 5px;
          color: #bdbdbd;
        }
        th {
          border-radius: 0;
          padding: 12px 17px;
        }
        .table-head-icon {
          stroke: #464646;
        }
      }
    }
    tbody {
      tr {
        border-bottom: 1px solid #f2f2f2;
        td {
          border-radius: 0;
          padding: 12px 14px;
          font-size: 13px;
          .table-icon-indicator {
            width: 50px;
            stroke: #8d8d8d;
          }
        }
        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}
.dark-color-text {
  color: var(--airbag-black);
}
