div#onboarding-detail {
	#top-ctas {
		text-align: right;
		margin-top: 12px;
		button {
			margin-left: 6px;
			svg {
				margin-right: 6px;
			}
		}
	}
	#status-indicators {
		margin: 18px 0;
		button {
			margin-right: 6px;
			border: 0px;
			.op-label {
				margin-left: 6px;
			}
			.count-badge {
				font-size: 10px;
				margin-left: 3px;
			}
		}
		.green-pill {
			background-color: var(--airbag-successful);
			color: #fff;
			svg {
				color: #fff;
			}
		}
		.red-pill {
			background-color: var(--airbag-error);
			color: #fff;
			svg {
				color: #fff;
			}
		}
		.blue-pill {
			background-color: var(--airbag-blue);
			color: #fff;
			svg {
				color: #fff;
			}
		}
		.yellow-pill {
			background-color: var(--airbag-attention);
			color: var(--airbag-lightBlack);
			svg {
				color: #fff;
			}
		}
		.blue-i {
			color: var(--airbag-blue);
		}
		.red-i {
			color: var(--airbag-error);
		}
		.green-i {
			color: var(--airbag-successful);
		}
	}
	.edited {
		background-color: var(--airbag-attention-light);
	}
	.delete-row {
		color: var(--airbag-error);
		margin: 6px;
		vertical-align: middle;
		cursor: pointer;
	}
	.pill-cont {
		span {
			padding: 3px 9px;
			border-radius: 18px;
			svg {
				margin-right: 6px;
			}
		}
	}
	.green-td {
		span {
			background-color: var(--airbag-successful);
			color: #fff
		}
	}
	.red-td {
		span {
			background-color: var(--airbag-error);
			color: #fff
		}
	}
	.blue-td {
		span {
			background-color: var(--airbag-blue);
			color: #fff
		}
	}
	.yellow-td {
		span {
			background-color: var(--airbag-yellow);
			color: #fff
		}
	}
	.edit-input {
		height: 30px;
		width: 150px;
	}
	.cursor-pointer {
		cursor: pointer;
	}
	.cursor-not-allowed {
		cursor: not-allowed;
	}
	.edit-span {
		cursor: pointer;
		svg {
			color: var(--airbag-yellow);
			margin-right: 6px;
		}
	}
}