div#_manager_ {
  select {
    background-color: #f2f2f2 !important;
    display: flex !important;
  }
  .airbag-table-container {
    margin-top: 15px;
  }
  .pos-index {
    margin-right: 5px;
    color: #a7a7a7;
  }
  #ctas {
    #edit-btn {
      background-color: #1e96dc;
      stroke: #fff;
      cursor: pointer;
      padding: 4px;
      border-radius: 35px;
      width: 35px;
      height: 35px;
      text-align: center;
      vertical-align: middle;
      line-height: 35px;
    }
  }
  #name {
    text-align: center;
    margin-bottom: 25px;
    h5 {
      font-weight: bold;
      margin-bottom: 0;
    }
    #firestore-id-manager {
      input {
        display: inline-block;
        font-family: monospace;
        font-size: 12px;
        text-align: center;
        cursor: pointer;
        width: 100%;
        max-width: 250px;
        height: 25px;
        margin: 5px 0 !important;
      }
    }
    #type {
      margin: 0 0 10px 0;
      color: #6f6f6f;
      font-size: 12px;
      font-style: italic;
      font-weight: 100px;
    }
    p {
      margin: 0;
    }
    button {
      margin-top: 10px;
    }
  }
  .status {
    min-width: 100px;
    padding: 2px 10px;
    border-radius: 42px;
    font-weight: 600;
    font-size: 12px;
    margin: 3px;
    &.error {
      background-color: #ffdad6;
      color: #c91316;
    }
    &.warning {
      background-color: #fff093;
      color: #9c8b17;
    }
    &.ok {
      background-color: #d2f0cd;
      color: #12ba90;
    }
  }
  #manager-menu {
    background-color: #fff;
    border-radius: 6px;
    padding: 12px 6px 0 6px;
    overflow: auto;
    white-space: nowrap;
    margin-bottom: 20px;
    .sec {
      padding: 3px 18px 6px 18px;
      font-size: 12px;
      color: #8d8d8d;
      cursor: pointer;
      font-weight: bold;
      display: inline-block;
      margin: 0 0 12px 0;
      transition: 0.2s;
      border-bottom-color: var(--airbag-yellow);
      &:hover {
        .sec-content {
          color: #000;
          border-bottom: 2px solid var(--airbag-yellow);
        }
      }
      &:not(:last-child) {
        border-right: 1px solid #ccc;
      }
      .sec-content {
        padding: 3px 3px 6px 3px;
      }
      &.active {
        .sec-content {
          color: #000;
          border-bottom: 2px solid var(--airbag-yellow);
        }
        #beta {
          color: #000;
        }
        .sect-icon {
          stroke: #000;
        }
      }
      #beta {
        font-size: 9px;
        font-weight: lighter;
      }
      .sect-icon {
        margin: 0 5px -3px 0;
        width: 15px;
        stroke: #848484;
      }
    }
  }
  .big-label {
    margin-bottom: 0;
    p {
      margin-bottom: 20px;
      font-weight: bold;
      color: #464646;
    }
  }
  .fake-input {
    background-color: #f2f2f2;
    padding: 11px 10px;
    height: 3rem;
    border-radius: 40px;
    margin-top: 0;
  }
  #bottom-buttons {
    button {
      margin: 3px;
    }
  }
}
