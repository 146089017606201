div._side-modal_ {
  .side-modal {
    height: calc(100% - 70px);
    overflow-y: auto;
    width: 0;
    background-color: #fff;
    position: fixed;
    z-index: 99;
    bottom: 0;
    transition: 0.2s ease;
    -webkit-transition: 0.2s ease;
    -moz-transition: 0.2s ease;
    -o-transition: 0.2s ease;
    -ms-transition: 0.2s ease;
    #close-icon {
      stroke-width: 25px;
    }
    @media only screen and (max-width: 600px) {
      right: 0;
    }
    @media only screen and (min-width: 601px) {
      left: 0;
    }
    &.open {
      @media only screen and (max-width: 600px) {
        width: 100% !important;
      }
      @media only screen and (min-width: 601px) {
        width: 600px !important;
      }
    }
    .sidemodal-header {
      margin: 10px 0;
      @media only screen and (min-width: 601px) {
        text-align: right;
      }
      svg {
        font-size: 24px;
        margin: 10px 25px 0 25px;
        color: #464646;
        cursor: pointer;
      }
    }
    .sidemodal-body {
      padding: 0 25px 40px 25px;
    }
  }
  .sidemodal-courtain {
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 19;
    display: none;
    background-color: rgba(0, 0, 0, 0.4);
    &.show-sidemodal-courtain {
      display: block !important;
      position: fixed;
      top: 0;
      left: 0;
    }
  }
}
