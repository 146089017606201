#_admin-home_ {
  #admin-title {
    text-align: center;
    font-weight: bold;
  }
  .box-wrapp {
    margin-top: 30px;
    padding: 0 10px;
    @media only screen and (max-width: 600px) {
      text-align: center;
    }
    #box {
      display: inline-block;
      width: 100%;
      max-width: 300px;
      background-color: #fff;
      text-align: center;
      padding: 20px 10px;
      border-radius: 6px;
      -webkit-box-shadow: 0px 6px 15px -4px rgba(222, 222, 222, 1);
      -moz-box-shadow: 0px 6px 15px -4px rgba(222, 222, 222, 1);
      box-shadow: 0px 6px 15px -4px rgba(222, 222, 222, 1);
      transition: 0.2s;
      -webkit-transition: 0.2s;
      -moz-transition: 0.2s;
      -o-transition: 0.2s;
      -ms-transition: 0.2s;
      &:hover {
        background-color: #f7f7f7;
      }
      svg {
        color: #464646;
        transition: 0.2s;
        -webkit-transition: 0.2s;
        -moz-transition: 0.2s;
        -o-transition: 0.2s;
        -ms-transition: 0.2s;
      }
      p {
        margin: 0;
        color: #000;
      }
    }
  }
}
