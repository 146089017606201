div#_add-employee_ {
	#main-t {
		text-align: center;
		margin-bottom: 25px;
		color: #f4d23a;
	}
	form {
		.big-label {
			margin-bottom: 0;
			p {
				margin-bottom: 20px;
				font-weight: bold;
				color: #464646;
			}
		}
		input,
		select {
			background-color: #f2f2f2;
		}
	}
}
