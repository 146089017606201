#_company-drivers_ {
  margin-top: 25px;
  select,
  input {
    display: inline-block;
    width: 100%;
    background-color: #fff;
  }
  .item-container {
    display: inline-block;
    @media only screen and (max-width: 600px) {
      margin-bottom: -10px;
      width: 100%;
    }
    @media only screen and (min-width: 601px) {
      width: 100%;
      margin-bottom: -4px;
    }
  }
  #query-clear {
    position: absolute;
    margin: 14px 0 0 -20px;
    color: #2f2f2f;
    cursor: pointer;
  }
  .hide-on-large-and-down {
    @media only screen and (max-width: 1200px) {
      display: none;
    }
  }
  #main-title {
    text-align: center;
    font-weight: bold;
    @media only screen and (max-width: 600px) {
      margin-top: 25px;
    }
  }
  #filters {
    margin-bottom: 0;
    input {
      background-color: #fff;
      width: 100%;
      max-width: 200px;
    }
    #add-icon {
      background-color: #1e96dc;
      cursor: pointer;
      color: #fff;
      padding: 8px;
      border-radius: 35px;
      width: 35px;
      height: 35px;
      text-align: center;
      vertical-align: middle;
      line-height: 35px;
    }
  }
  #no-data {
    text-align: center;
    margin-top: 25px;
    font-weight: 700;
    #error-icon {
      width: 100px;
      stroke: var(--airbag-grey);
    }
    p {
      margin-top: 0;
    }
  }
  #drivers-table {
    margin-top: 25px;
    margin-bottom: 100px;
    a {
      color: inherit;
    }
    .pos-index {
      margin-right: 5px;
      color: #a7a7a7;
    }
    tbody {
      tr {
        transition: 0.2s;
        &:hover {
          background-color: #f7f7f7;
          cursor: pointer;
        }
      }
    }
    .pills-container {
      text-align: center;
      .pill {
        display: inline-block;
        text-align: center;
        width: 90px;
        padding: 3px 10px;
        border-radius: 42px;
        font-weight: 600;
        font-size: 10px;
        &.red-s {
          background-color: #ffdad6;
          color: #c91316;
        }
        &.yellow-s {
          background-color: #fef1d0;
          color: #ffb800;
        }
        &.green-s {
          background-color: #c3eddc;
          color: #008e6c;
        }
        &.grey-s {
          background-color: #dadada;
          color: #343538;
        }
      }
    }
  }
  #company-menu {
    background-color: #fff;
    margin: 20px 0 12px 0;
    border-radius: 6px;
    text-align: center;
    .col {
      padding: 10px 0;
      cursor: pointer;
      transition: 0.2s;
      -webkit-transition: 0.2s;
      -moz-transition: 0.2s;
      -o-transition: 0.2s;
      -ms-transition: 0.2s;
      &:hover {
        background-color: #f9f9f9;
      }
      &.active {
        background-color: #f9f9f9;
        svg {
          color: #f4db3a;
        }
        .subtitle {
          font-size: small;
          font-weight: bold;
          color: #ceb82b;
        }
      }
    }
    .title {
      font-size: 18px;
      margin: 0;
      font-weight: bold;
      svg {
        font-size: 14px;
        margin-right: 10px;
        color: #cecece;
        width: 20px;
      }
    }
    .subtitle {
      font-size: 12px;
      margin: 0;
      color: #6b6b6b;
    }
  }
}
