div._breadcrumbs_ {
  margin-bottom: 0;
  font-family: var(--airbag-font);
  text-transform: capitalize;
  color: #333;
  @media only screen and (max-width: 600px) {
    margin-top: 15px;
  }
  .bold {
    font-weight: 600;
  }
  .separator {
    color: #9e9e9e;
    width: 10px;
    @media only screen and (max-width: 600px) {
      margin: 0 5px;
    }
    @media only screen and (min-width: 601px) {
      margin: 0 10px;
    }
  }
  a {
    color: inherit;
  }
}
