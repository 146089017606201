:root {
  --airbag-yellow: #f6c700;
  --airbag-lightBlack: #2e2b2c;
  --airbag-black: #000000;
  --airbag-grey: #757679;
  --airbag-light: #f8f8f8;
  --airbag-grey-light: #dadada;
  --airbag-alert: #c91316;
  --airbag-blue: #1baff2;
  --airbag-fontRegular: 400;
  --airbag-fontLight: 200;
  --airbag-fontBold: 700;
  --airbag-font: PP Object Sans;
  --airbag-yellow-highlight: rgb(255, 229, 0, 0.3);
  --airbag-iconLink-color: #b5b5b5;
  --airbag-white: #ffffff;
  --airbag-dark-light: #343538;

   /* Airbag Status Colors */
   --airbag-successful: #008e6c;
   --airbag-successful-light: #c3eddc;
   --airbag-attention: #ffb800;
   --airbag-attention-light: #fef1d0;
   --airbag-error: #c91316;
   --airbag-error-light: #ffdad6;

  /*Airbag Buttons variables*/
  --airbag-buttonBlue: linear-gradient(
    90deg,
    rgba(37, 82, 254, 1) 2%,
    rgba(37, 82, 254, 0.7) 100%
  );
  --airbag-buttonBlue-circle: rgb(34, 76, 241);
  --airbag-buttonBlue-hover: linear-gradient(
    90deg,
    rgba(33, 73, 263, 1) 2%,
    rgba(33, 73, 263, 0.8) 100%
  );
  --airbag-buttonBlue-circle-hover: rgb(24, 61, 207);
  --airbag-button-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  --airbag-button-webkit-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  --airbag-button-moz-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  --airbag-buttonBlack: linear-gradient(
    90deg,
    #3e3e3e 2.32%,
    rgba(41, 41, 41, 0.8) 100%
  );
  --airbag-buttonBlack-circle: rgb(31, 28, 29);
  --airbag-buttonBlack-hover: linear-gradient(
    90deg,
    rgb(24, 22, 22) 2%,
    rgba(24, 22, 22, 0.8) 100%
  );
  --airbag-buttonBlack-circle-hover: rgb(5, 4, 5);

  --airbag-buttonYellow: linear-gradient(
    90deg,
    rgba(255, 230, 0, 1) 2%,
    rgba(255, 230, 0, 0.4) 100%
  );
  --airbag-buttonYellow-circle: rgb(241, 218, 3);
  --airbag-buttonYellow-hover: linear-gradient(
    90deg,
    rgba(228, 205, 2, 1) 2%,
    rgba(238, 214, 2, 0.5) 100%
  );
  --airbag-buttonYellow-circle-hover: rgb(224, 203, 10);

  --airbag-buttonRed: linear-gradient(
    90deg,
    rgba(230, 50, 41, 1) 2%,
    rgba(230, 50, 41, 0.7) 100%
  );
  --airbag-buttonRed-circle: rgb(218, 41, 32);
  --airbag-buttonRed-hover: linear-gradient(
    90deg,
    rgb(216, 46, 37) 2%,
    rgba(216, 46, 37, 0.7) 100%
  );
  --airbag-buttonRed-circle-hover: rgb(211, 35, 26);
}
