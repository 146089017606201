#admin-companies {
  margin-top: 20px;
  #main-title {
    text-align: center;
    font-weight: bold;
    margin-bottom: 25px;
    @media only screen and (max-width: 600px) {
      margin-top: 25px;
    }
  }
  #stats {
    text-align: center;
    background-color: #fff;
    border-radius: 6px;
    padding: 20px 5px 5px 5px;
    -webkit-box-shadow: 0px 6px 15px -4px rgba(222, 222, 222, 1);
    -moz-box-shadow: 0px 6px 15px -4px rgba(222, 222, 222, 1);
    box-shadow: 0px 6px 15px -4px rgba(222, 222, 222, 1);
  }
  #filters {
    text-align: right;
    margin-bottom: 15px;
    #add-icon {
      background-color: #1e96dc;
      cursor: pointer;
      color: #fff;
      stroke: #fff;
      stroke-width: 25px;
      padding: 8px;
      border-radius: 35px;
      width: 35px;
      height: 35px;
      text-align: center;
      vertical-align: middle;
      line-height: 35px;
    }
  }
  #no-data {
    text-align: center;
    margin-top: 60px;
    font-weight: 700;
    #error-icon {
      width: 100px;
      font-size: 3em;
      color: var(--airbag-yellow);
    }
    p {
      margin-top: 12px;
      font-size: 20px;
    }
  }
  .custom-menu {
    background-color: #fff;
    border-radius: 6px;
    padding: 12px 6px 0 6px;
    overflow: auto;
    white-space: nowrap;
    .menu-container {
      .sec {
        padding: 3px 18px 6px 18px;
        font-size: 12px;
        color: #797979;
        cursor: pointer;
        font-weight: bold;
        display: inline-block;
        margin: 0 0 12px 0;
        transition: 0.2s;
        border-bottom-color: #f6c700;
        min-width: 115px;
        text-align: center;
        &:hover {
          .sec-content {
            color: #000;
            border-bottom: 2px solid #f6c700;
          }
        }
        &:not(:last-child) {
          border-right: 1px solid #dadada;
        }
        .sec-content {
          padding: 3px 3px 6px 3px;
        }
        &.active {
          .sec-content {
            color: #181818;
            border-bottom: 2px solid #f6c700;
          }
          .sect-icon {
            stroke: #181818;
          }
        }
        .sect-icon {
          margin: 0 5px -3px 0;
          width: 15px;
          stroke: #797979;
        }
      }
    }
  }
  #companies-table {
    a {
      color: inherit;
    }
    .company-link {
      cursor: pointer;
      color: #fff;
      padding: 3px;
      border-radius: 35px;
      stroke-width: 2px;
      width: 20px;
      height: 20px;
      margin-left: 5px;
      text-align: center;
      vertical-align: middle;
      line-height: 25px;
      background-color: #f6c700;
      stroke: #343538;
    }
    .pos-index {
      margin-right: 5px;
      color: #a7a7a7;
    }
  }
}
