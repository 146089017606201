.trips-container {
  .trip-id {
    a {
      text-decoration: inherit;
      color: inherit;
    }
    &:hover {
      border-bottom: 1px solid var(--airbag-yellow);

      cursor: pointer;
    }
  }
  .pos-index {
    margin-right: 5px;
    color: var(--airbag-grey-light);
  }

  .trips-button-container {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
  }
}

#general-info {
  background-color: var(--airbag-white);
  text-align: center;
  margin: 0 0 12px 0;
  border-radius: 6px;
  padding: 24px;
  display: flex;
  @media only screen and (max-width: 750px) {
    flex-wrap: wrap;
  }

  .col {
    padding: 10px 5px;
    @media only screen and (max-width: 450px) {
      min-height: 85px;
    }
  }
  .info-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .title {
    font-size: 18px;
    margin: 0;
    font-weight: bold;
    .title-icon {
      margin: 0 10px -2px 0;
      width: 18px;
    }
    .title-fa-icon {
      font-size: 14px;
      margin-right: 10px;
      color: var(--airbag-dark-light);
    }
  }
  #time-icon {
    margin-bottom: -5px;
    stroke-width: 30px;
    stroke: var(--airbag-dark-light);
  }
  #velocimeter-icon {
    margin-bottom: -5px;
    stroke-width: 30px;
    stroke: var(--airbag-dark-light);
  }
  .subtitle {
    font-size: 12px;
    margin: 0;
    color: var(--airbag-dark-light);
  }
}

.trip-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.parameters-container {
  border-radius: 6px;
  background-color: var(--airbag-white);
  color: var(--airbag-dark-light);

  .sections-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 12px;
  }

  .heading {
    text-align: center;
  }

  .section {
    padding: 10px;

    p {
      margin: 5px 0;
    }
  }
}

.no-data {
  text-align: center;
  color: #999;
}

.show-parameters,
.close-parameters {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    color: var(--airbag-primary);
  }
}
