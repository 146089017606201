div#_company-managers_ {
  #manager-filters {
    margin-top: 25px;
    #add-icon {
      background-color: #1e96dc;
      cursor: pointer;
      stroke: #fff;
      padding: 4px;
      border-radius: 35px;
      width: 35px;
      height: 35px;
      text-align: center;
      vertical-align: middle;
      line-height: 35px;
    }
  }
  #no-data {
    text-align: center;
    margin-top: 25px;
    font-weight: 700;
    #error-icon {
      width: 100px;
      stroke: var(--airbag-grey);
    }
    p {
      margin-top: 0;
    }
  }
  #managers-table {
    tbody {
      tr {
        transition: 0.2s;
        &:hover {
          background-color: #f7f7f7;
          cursor: pointer;
        }
      }
    }
    .pos-index {
      margin-right: 5px;
      color: #a7a7a7;
    }
    .status {
      padding: 2px 10px;
      border-radius: 42px;
      font-weight: 600;
      font-size: 10px;
      svg {
        font-size: 8px;
        margin-right: 5px;
      }
      &.error {
        background-color: #ffdad6;
        color: #c91316;
      }
      &.ok {
        background-color: #d2f0cd;
        color: #12ba90;
      }
    }
  }
}
