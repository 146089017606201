div#_edit-manager_ {
  #main-t {
    text-align: center;
    margin-bottom: 25px;
  }
  form {
    .big-label {
      margin-bottom: 0;
      p {
        margin-bottom: 20px;
        font-weight: bold;
        color: #464646;
      }
    }
    input,
    select {
      background-color: #f2f2f2;
    }
  }
}
