div._phone-input_ {
  select.phone-input-country-code {
    float: left;
    width: 38% !important;
    display: block;
    border-radius: 40px 0 0 40px !important;
    border-right: 1px solid #e2e2e2 !important;
  }
  input.phone-input-number {
    width: 62% !important;
    border-radius: 0 40px 40px 0 !important;
  }
}
