#error-boundary {
  height: 100vh;
  padding: 10px;
  background-color: #f4db3a;
  #error-boundary-content {
    margin: 0 auto;
    text-align: center;
    width: 100%;
    max-width: 900px;
    svg {
      font-size: 100px;
      color: #464646;
    }
    h3 {
      font-family: var(--airbag-font);
      font-weight: bold;
      color: #464646;
    }
    h5 {
      font-family: var(--airbag-font);
      font-weight: 600;
      margin: 25px 0 42px 0;
      color: #464646;
      line-height: 35px;
    }
  }
}
