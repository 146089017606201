.button {
  font-family: var(--airbag-font);
  color: #ffffff;
  border: 1px solid;
  border-radius: 40px;
  cursor: pointer;
  font-size: 14px;
  padding: 8px 15px 6px 15px;
  transition: 0.2s;
  font-weight: bold;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  -ms-transition: 0.2s;
  &:disabled {
    opacity: 0.8;
    cursor: unset;
  }
  .preloader-wrapper {
    width: 10px;
    height: 10px;
    margin-right: 10px;
    .spinner-layer {
      border-color: inherit;
    }
  }
  &.block {
    display: block;
    width: 100%;
  }
  .button-icon {
    margin-right: 8px;
  }
}
.small-button {
  padding: 8px 10px 6px 10px;
  font-size: 11px;
}
.dark-button {
  background-color: #464646;
  border-color: #464646;
  color: #fff;
  &:focus {
    background-color: #464646;
  }
}
.dark-button-border {
  background-color: #464646;
  border-color: #fff;
  color: #fff;
  &:focus {
    background-color: #464646;
  }
}
.yellow-button {
  background-color: #f4db3a;
  border-color: #f4db3a;
  color: #2d2d2d;
  &:focus {
    background-color: #f4db3a;
  }
}
.red-button {
  background-color: #f93d3d;
  border-color: #f93d3d;
  color: #fff;
  &:focus {
    background-color: #f93d3d;
  }
}
.blue-button {
  background-color: var(--airbag-blue);
  border-color: var(--airbag-blue);
  color: #fff;
  &:focus {
    background-color: var(--airbag-blue);
  }
}
.green-button {
  background-color: var(--airbag-successful);
  border-color: var(--airbag-successful);
  color: #fff;
  &:focus {
    background-color: var(--airbag-successful-light);
  }
}
.clear-button {
  background-color: transparent;
  border-color: #464646;
  color: #464646;
  &:focus {
    background-color: transparent;
  }
}
.white-button {
  background-color: #fff;
  border-color: #464646;
  color: #464646;
  &:focus {
    background-color: #fff;
  }
}
