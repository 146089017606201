div#onboarding-new-company {
	width: 100%;
	max-width: 600px;
	margin: 0 auto;
	input {
		background-color: #fff;
	}
	#cancel-top {
		margin-bottom: 30px;
	}
	.next-button-div {
		margin-top: 30px;
		button {
			margin: 3px;
		}
	}
	.error {
		color: red;
		font-size: 12px;
		margin: 15px 0;
	}
	#step4 {	
		span {
			margin: 10px;
		}
		#selected-mother {
			margin-top: 12px;
		}
	}
}