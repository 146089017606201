#loader {
  height: 100vh;
  width: 100%;
  div {
    margin-top: 500px;
  }
}

#admin-main {
  background-color: #f2f2f2;

  #admin-content {
    min-height: 100vh;
    padding: 100px 0 40px 0;
    margin: 0 auto;
    max-width: 1500px;
    @media only screen and (max-width: 600px) {
      width: 98%;
    }
    @media only screen and (min-width: 601px) {
      width: 90%;
    }
    .name-title {
      font-family: var(--airbag-font);
      font-weight: 900;
      color: #464646;
      text-align: right;
      margin-bottom: 0;
    }
  }
}
