div#_massive-notifications_ {
	background-color: #fff;
	border-radius: 6px;
	padding: 12px;
	width: 100%;
	max-width: 400px;
	margin: 30px auto 12px auto;
	h5 {
		font-weight: bold;
		margin-bottom: 30px;
	}
	select {
		background-color: #f2f2f2;
		display: block;
	}
	#notif-menu {
    background-color: #fff;
    border-radius: 6px;
    padding: 12px 6px 0 6px;
    overflow: auto;
    white-space: nowrap;
    margin-bottom: 20px;
    .sec {
      padding: 3px 18px 6px 18px;
      font-size: 12px;
      color: #8d8d8d;
      cursor: pointer;
      font-weight: bold;
      display: inline-block;
      margin: 0 0 12px 0;
      transition: 0.2s;
      border-bottom-color: var(--airbag-yellow);
      &:hover {
        .sec-content {
          color: #000;
          border-bottom: 2px solid var(--airbag-yellow);
        }
      }
      &:not(:last-child) {
        border-right: 1px solid #ccc;
      }
      .sec-content {
        padding: 3px 3px 6px 3px;
      }
      &.active {
        .sec-content {
          color: #000;
          border-bottom: 2px solid var(--airbag-yellow);
        }
        .sect-icon {
          stroke: #000;
        }
      }
      #beta {
        font-size: 9px;
        font-weight: lighter;
      }
      .sect-icon {
        margin: 0 5px -3px 0;
        width: 15px;
        stroke: #848484;
      }
    }
  }
}