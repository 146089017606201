.no-scroll {
  overflow: hidden !important;
}
#admin-sidenav {
  height: calc(100% - 70px);
  width: 0;
  background-color: #fff;
  position: fixed;
  z-index: 99;
  left: 0;
  bottom: 0;
  overflow: hidden;
  transition: 0.2s ease;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
  -o-transition: 0.2s ease;
  -ms-transition: 0.2s ease;
  #sidenav-top {
    background-color: #fff;
    padding: 20px 5px;
    #cerrar {
      position: absolute;
      font-size: 40px;
      top: -5px;
      right: 16px;
    }
    #sidenav-logo {
      margin-bottom: 10px;
      width: 90px;
    }
    #sidenav-profile {
      text-align: center;
      padding-top: 2px;
      font-family: var(--airbag-font);
      p {
        margin: 0;
        font-weight: 900;
        margin-bottom: 2px;
      }
    }
  }
  #sidenav-wrapper {
    background: #fff;
    height: 100%;
    .sidenav-inner {
      margin-top: 25px;
      .links {
        list-style-type: none;
        margin-top: 0;
        padding-left: 0;
        width: 100%;
        font-family: var(--airbag-font);
        #home {
          fill: var(--airbag-lightBlack);
          stroke: #fff;
          margin-bottom: -7px;
        }
        .active {
          svg {
            color: var(--airbag-yellow);
          }
          p {
            font-weight: 600;
          }
          #home {
            fill: var(--airbag-yellow) !important;
          }
        }
        li {
          height: 50px;
          width: 100%;
          display: table;
          transition: 0.2s;
          -webkit-transition: 0.2s;
          -moz-transition: 0.2s;
          -o-transition: 0.2s;
          -ms-transition: 0.2s;
          &:hover {
            background-color: #f7f7f7;
            #home {
              stroke: var(--airbag-light);
            }
          }
        }
        .icon {
          font-size: 18px;
          color: var(--airbag-lightBlack);
          width: 25px;
        }
        a,
        span {
          padding-left: 42px;
          vertical-align: middle;
          display: table-cell;
          color: inherit;
          text-decoration: none;
          cursor: pointer;
          font-family: inherit;
        }
        p {
          display: inline;
          vertical-align: middle;
          margin-left: 20px;
          font-weight: 400;
        }
      }
    }
  }
}
.open-sn {
  width: 300px !important;
}
#sidenav-courtain {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 19;
  display: none;
  background-color: rgba(0, 0, 0, 0.7);
}
.show-nc {
  display: block !important;
}
#admin-navbar {
  width: 100%;
  height: 70px;
  position: fixed;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 5px 8px;
  background-color: var(--airbag-lightBlack);
  border-bottom: solid 2px var(--airbag-yellow);
  padding: 5px 0;
  z-index: 19;
  #sa-logo {
    width: 144px;
    margin: 0 auto 0 45px;
  }
  #menu-icon {
    margin: 0 20px 0 auto;
    width: 40px;
  }
  .burger {
    width: 25px;
    height: 20px;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    span {
      background-color: #fff;
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
      &:nth-child(1) {
        top: 0px;
      }
      &:nth-child(2),
      &:nth-child(3) {
        top: 8px;
      }
      &:nth-child(4) {
        top: 16px;
      }
    }
  }
  .open {
    span {
      &:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
      }
      &:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
      &:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
      }
    }
  }
}
