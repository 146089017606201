div#_reports_ {
  #main-title {
    text-align: center;
    font-weight: bold;
    margin-bottom: 25px;
    @media only screen and (max-width: 600px) {
      margin-top: 25px;
    }
  }
  #reports-menu {
    border-radius: 6px;
    text-align: center;
    background-color: #fff;
    margin: 18px 0.5rem 0 0.5rem;
    span {
      font-weight: bold;
      color: #cdcdcd;
      padding: 5px;
      &.active {
        color: #464646;
        border-bottom: 3px solid #ffe500;
        svg {
          color: #ffe500;
        }
        #usage-reports-icon {
          stroke: #ffe500 !important;
        }
        #assistance-reports-icon {
          stroke: #ffe500 !important;
        }
      }
      svg {
        margin-right: 10px;
        color: #ccc;
      }
    }
    .col {
      cursor: pointer;
      transition: 0.2s;
      -webkit-transition: 0.2s;
      -moz-transition: 0.2s;
      -o-transition: 0.2s;
      -ms-transition: 0.2s;
      @media only screen and (max-width: 600px) {
        padding: 18px 0;
      }
      @media only screen and (min-width: 601px) {
        padding: 25px 0;
      }
      @media only screen and (min-width: 993px) {
        &:hover {
          background-color: #f7f7f7;
        }
      }
    }
  }
}
