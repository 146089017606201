#nav {
  position: fixed;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
  #logo {
    height: 80px !important;
    width: 200px;
    margin: 5px 0 0px 6px;
  }

  button {
    margin: 25px 0 20px 35px;
  }
}
#login {
  min-height: 100vh;
  padding: 120px 0 50px 0;
  font-family: var(--airbag-font);
  background: #151515;
  background: -webkit-linear-gradient(#464646 0%, #151515 100%);
  background: -o-linear-gradient(#464646 0%, #151515 100%);
  background: linear-gradient(#464646 0%, #151515 100%);
  #sam-logo {
    width: 100px;
    margin: 4px 0 10px 0;
  }
  #register {
    font-size: 12px;
    a {
      color: inherit;
      font-weight: bold;
    }
  }
  #fpl {
    color: #656565;
    font-size: 12px;
    margin: 20px 0 0 0;
  }
  #box {
    background-color: #fff;
    width: 100%;
    margin: 0 auto;
    border-radius: 10px;
    -webkit-box-shadow: 0px 6px 15px -4px rgba(50, 50, 50, 1);
    -moz-box-shadow: 0px 6px 15px -4px rgba(50, 50, 50, 1);
    box-shadow: 0px 6px 15px -4px rgba(50, 50, 50, 1);
    @media only screen and (max-width: 600px) {
      padding: 25px 15px 10px 15px;
    }
    @media only screen and (min-width: 601px) {
      max-width: 400px;
      padding: 25px 30px 10px 30px;
    }
    h5 {
      margin-top: 0px;
    }
    form {
      margin-top: 20px;
      font-family: var(--airbag-font);
      input {
        font-family: var(--airbag-font);
        background-color: #f2f2f2;
      }
    }
  }
}
